<template>
  <div class="modal">
    <div v-if="currentStepper == 0" class="modal__step-0">
      <div class="header">
        <div class="header__title">
          <span> Bem vindo a Zoox Eye Data to Act </span>
        </div>
        <v-icon @click="close"> mdi-close </v-icon>
      </div>
      <div class="body">
        <figure>
          <img src="/images/help_step_1.png" />
        </figure>

        <span>
          Aqui você tem tudo o que precisa para impactar <strong>quem</strong> é sua
          audiência, <strong>quando</strong> precisar e <strong>como</strong> quiser!
        </span>
      </div>
    </div>

    <div v-if="currentStepper == 1" class="modal__step-1">
      <div class="header">
        <figure>
          <img src="/images/help_step_2.png" />
        </figure>
      </div>
      <div class="body">
        <div class="body__title">
          <span>Comece adicionando uma ação</span>
        </div>
        <div class="body__descriptions">
          <p>
            <strong> Clicar no + </strong> para adicionar ao fluxo uma ação para
            <strong>quem</strong> você quer impactar
            <strong> (Audiência), como (Canal) e quando (Decisão). </strong>
          </p>
          <p>
            Já incluímos a audiência para você começar!
            <strong>Clique no card de ação no fluxo para editar as informações.</strong>
          </p>
        </div>
      </div>
    </div>

    <div v-if="currentStepper == 2" class="modal__step-2">
      <div class="header">
        <figure>
          <img src="/images/help_step_3.png" />
        </figure>
      </div>
      <div class="body">
        <div class="body__title">
          <span>Configure sua jornada</span>
        </div>
        <div class="body__descriptions">
          <p>Você pode ter diversos temporizadores para impactar seus clientes.</p>
        </div>
      </div>
    </div>

    <div v-if="currentStepper == 3" class="modal__step-3">
      <div class="body">
        <div class="body__title">
          <span>Salve sua jornada</span>
        </div>
        <div class="body__descriptions">
          <p>
            Você pode salvar sua jornada como rascunho caso queira voltar e editar ela
            mais tarde.
          </p>
        </div>
      </div>
    </div>

    <div v-if="currentStepper == 4" class="modal__step-4">
      <div class="header">
        <figure>
          <img src="/images/help_step_4.png" />
        </figure>
      </div>
      <div class="body">
        <div class="body__title">
          <span>Valide sua jornada e confira seu desempenho!</span>
        </div>
        <div class="body__descriptions">
          <p>
            Ao validar sua jornada,
            <strong
              >você pode acessar um relatório completo do impacto da sua campanha em tempo
              real.</strong
            >
          </p>
        </div>
      </div>
    </div>

    <div v-if="currentStepper == 5" class="modal__step-5">
      <div class="body">
        <div class="body__title">
          <span>Se tiver dúvida, estamos aqui!</span>
        </div>
        <div class="body__descriptions">
          <p>
            Continue usando nossa plataforma, e se tiver alguma dúvida, você pode sempre
            voltar ao tutorial quando precisar.
          </p>
        </div>
      </div>
    </div>

    <div class="modal--actions">
      <div class="modal--actions__dots">
        <div class="dot" :class="{ active: currentStepper == 0 }"></div>
        <div class="dot" :class="{ active: currentStepper == 1 }"></div>
        <div class="dot" :class="{ active: currentStepper == 2 }"></div>
        <div class="dot" :class="{ active: currentStepper == 3 }"></div>
        <div class="dot" :class="{ active: currentStepper == 4 }"></div>
        <div class="dot" :class="{ active: currentStepper == 5 }"></div>
      </div>
      <div v-if="currentStepper < 5" class="modal--actions__btns">
        <z-btn color="primary" text="pular tutorial" @click="close" />
        <z-btn primary text="próximo" @click="currentStepper++" />
      </div>
      <div v-else class="modal--actions__btns">
        <z-btn primary text="ok" @click="close" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentStepper: 0,
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.currentStepper = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.modal {
  background: #ffffff;
  border-radius: 15px;
  color: $z-black-2;
  overflow: hidden;

  &__step-0 {
    padding: $z-s-1 $z-s-1 0;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__title {
        span {
          font-weight: 700;
          font-size: 1.2rem;
          line-height: 23px;

          @media (max-width: 750px) {
            font-size: 0.99rem;
          }
        }
      }
    }
    .body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      figure {
        width: 260px;
        height: 260px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        @media (max-width: 750px) {
          width: 80%;
        }
      }

      span {
        font-weight: 300;
        font-size: 1.2rem;
        line-height: 23px;
      }
    }
  }

  &__step-1,
  &__step-2,
  &__step-3,
  &__step-4,
  &__step-5 {
    .header {
      figure {
        width: 100%;
        height: 220px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .body {
      padding: $z-s-1 $z-s-1 0;
      &__title {
        span {
          font-style: normal;
          font-weight: 700;
          font-size: 1.2rem;
        }
      }
      &__descriptions {
        p {
          font-weight: 400;
          font-size: 15px;
        }
      }
    }
  }

  &--actions {
    padding: 0 $z-s-1 $z-s-1;
    &__dots {
      padding: $z-s-1;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;
      .dot {
        width: 8px;
        height: 8px;
        background: #dddddd;
        border-radius: 50%;
      }
      .active {
        background: #0273c3;
      }
    }
    &__btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 0.5rem;
    }
  }
}
</style>
