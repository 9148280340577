<template>
  <div class="action-item" @click="$emit('clicked', dataProp)">
    <figure>
      <img :src="dataProp.imgPath" :alt="dataProp.name" />
    </figure>
    <article>
      <strong>
        {{ dataProp.name }}
      </strong>
    </article>
  </div>
</template>

<script>
export default {
  props: {
    dataProp: { type: Object, default: null },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.action-item {
  padding: 0.5rem $z-s-1;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  text-align: center;
  min-height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  figure {
    // border: 1px solid;
    width: 30px;
    height: 30px;

    img {
      width: 80%;
      height: 80%;
      object-fit: contain;
    }
  }
  article {
    strong {
      text-transform: uppercase;
      font-size: 0.6rem;

      @media (min-width: 1550px) {
        font-size: 0.7rem;
      }
    }
  }
}
</style>
