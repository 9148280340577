<template>
  <div class="modal">
    <div class="modal__header">
      <div class="modal__header__left">
        <span>Adicionar Tags</span>
      </div>
      <div>
        <z-btn primary text="+ Tag" @click="addAnotherTag"></z-btn>
      </div>
    </div>
    <div class="modal__body">
      <div class="modal__body__select mb-3">
        <v-row>
          <v-form ref="form" class="mx-2" lazy-validation style="width: 100%">
            <v-col
              v-for="el in Object.keys(localTags)"
              :key="el"
              cols="12" lg="6" md="6" sm="12">
              <z-input

                ref="tagname"
                v-model="localTags[parseInt(el)]"
                :hideDetails="true"
                :label="'Nome da tag '+el"
                style="margin-bottom: 10px"
                type="text"
              />
            </v-col>
          </v-form>
        </v-row>
      </div>
    </div>

    <div class="modal--actions">
      <z-btn text="Cancelar" @click="$emit('close')"></z-btn>
      <z-btn primary text="confirmar" @click="closeModal"></z-btn>
    </div>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";
export default {
  name: "TagUserModal",
  props: {
    clickedNode: {
      type: Object,
      default: () => null
    },
    tags: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      toggle_week_no: "",
      toggle_week_yes: "",
      iconStyle: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
      },
      http: new ApiClient(),
      audiences: [],
      tagName: null,
      selectedFilteredAudience: null,
      localTags: {
        1: ""
      },
    };
  },
  async created() {
    if(this.tags) {
      this.localTags = this.tags;
    }
  },
  watch: {
    tagName(nv) {
      if (nv) {
        if (this.percentageB) {
          this.$refs.form.validate();
        }
      }
    },
  },
  methods: {
    addAnotherTag() {
      var chaves = Object.keys(this.localTags).length;
      this.localTags[chaves+1] = ""
      this.$forceUpdate()
    },
    requiredAndFitvalue() {
      const valueA = parseInt(this.percentageA);
      const valueB = parseInt(this.percentageB);

      if (this.percentageA && !isNaN(valueA) && this.percentageB && !isNaN(valueB)) {
        return [valueA + valueB < 100];
      } else {
        return [false];
      }
    },
    async closeModal() {
      this.$emit("close", {tags: Object.values(this.localTags).join(';')});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  border-radius: 15px;
  color: $z-black-1;
  padding: $z-s-1;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &__left {
      span {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 15px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__body {
    margin: $z-s-1 0;
    // border: 2px solid;
    // display: flex;
    // align-items: flex-start;
    // flex-wrap: wrap;
    // gap: $z-s-1 $z-s-3;

    &__data {
      .title-data {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 15px;
      }

      &__table {
        margin-bottom: $z-s-4;

        .header,
        .body {
          .line {
            display: flex;
            align-items: center;
            gap: $z-s-1;
            margin-bottom: 0.5rem;
            & > div {
              flex: 1 1 300px;
              width: 100%;

              &:nth-child(2) {
                flex: 3 1 500px;
              }
            }
            // @media (max-width: 750px) {
            //   & > div {
            //     width: auto;
            //     span {
            //       font-size: 0.85rem;
            //       font-weight: 500;
            //     }
            //     &:nth-child(2) {
            //       flex: 1 1 300px !important;
            //     }
            //   }
            //   .hide {
            //     display: none;
            //   }
            // }
          }
        }

        .header {
          .line {
            & > div {
              span {
                font-weight: 700;
                font-size: 0.9rem;
                line-height: 15px;
                letter-spacing: 0.15px;
                text-transform: uppercase;
                color: #666666;
              }
            }
          }
        }
        .body {
          .line {
            .left {
              display: flex;
              align-items: center;
              gap: 0.5rem;

              span {
                font-weight: 500;
                font-size: 1rem;
                line-height: 15px;
                color: #333333;
              }
              figure {
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }
        }
      }
    }
  }
  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
