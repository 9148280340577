<template>
  <div class="modal">
    <div class="modal__header">
      <div class="modal__header__left">
        <span>Coleção</span>
      </div>
    </div>
    <div class="modal__body" v-if="!showCredentialForm">
      <div>
        <span>
          Insira a audiência a ser criada no
          {{ isGoogleModal ? "Google" : "Facebook" }}, ou selecione uma
          existente:
        </span>
      </div>
      <div>
        <z-select
          v-model="collectionId"
          placeholder="Clique aqui para iniciar"
          :rules="[(v) => !!v || 'Campo obrigatório']"
          :items="audiences"
          itemText="name"
          itemValue="value"
          label="Audiência"
          class="select"
        />
        <z-input
          v-if="collectionId === 'create-new'"
          v-model="name"
          label="Nome"
          :rules="[(v) => !!v || 'Campo obrigatório']"
          class="item"
          :hideDetails="true"
        />
      </div>
      Mapeamento de campos adicionais:
      <div>
        <table style="width: 100%">
          <thead>
            <th style="text-align: left">
              Campo {{ isGoogleModal ? "Google" : "Facebook" }}
            </th>
            <th>Campo Audiencia</th>
          </thead>
          <tbody v-if="!isGoogleModal">
            <tr v-for="(item, index) in facebookFields" :key="index">
              <td>{{ item }}</td>
              <td v-if="item === 'Genero'" style="padding-top: 20px">
                <z-select
                  v-model="fieldMap[item]"
                  :items="databaseFields"
                  hide-details
                  class="select mb-1"
                />

                <z-select
                  v-if="genOptions.length"
                  v-model="maleIdentifier"
                  :items="genOptions"
                  class="select mb-1"
                  hide-details
                />

                <z-select
                  v-if="genOptions.length"
                  v-model="femaleIdentifier"
                  :items="genOptions"
                  class="select mb-1"
                  hide-details
                />
              </td>
              <td v-else>
                <z-select
                  v-model="fieldMap[item]"
                  :items="databaseFields"
                  hide-details
                  class="select"
                />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(item, index) in googleFields" :key="index">
              <td>{{ item }}</td>
              <td>
                <z-select
                  v-model="fieldMap[item]"
                  :items="databaseFields"
                  class="select"
                  hide-details
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal--actions">
        <z-btn text="Cancelar" @click="$emit('close')"></z-btn>
        <z-btn primary @click="submit" text="Incluir"></z-btn>
      </div>
    </div>
    <div class="modal__body" v-else-if="showCredentialForm">
      <div>
        <span>
          Insira as credenciais da sua conta de anuncios do facebook:
        </span>
        <z-input
          v-model="fbData.fb_ad_account_id"
          label="Ad Account Id"
          :rules="[(v) => !!v || 'Campo obrigatório']"
          class="item"
          style="margin-top: 15px"
          :hideDetails="true"
        />
        <z-input
          v-model="fbData.fb_auth_token"
          label="Auth Token"
          :rules="[(v) => !!v || 'Campo obrigatório']"
          class="item"
          style="margin-top: 15px"
          :hideDetails="true"
        />
      </div>
      <div class="modal--actions">
        <z-btn text="Cancelar" @click="$emit('close')"></z-btn>
        <z-btn primary @click="submit" text="Incluir"></z-btn>
      </div>
    </div>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";

export default {
  async created() {
    await this.http
      .get(`templatesConfiguration/getVariableFields/${this.databaseConfigId}`)
      .then((resp) => {
        this.databaseFields = Object.keys(resp);
      })
      .catch((err) => {
        throw err;
      });

    await this.http
      .get(`dbConfiguration/getFirstData/${this.databaseConfigId}`)
      .then((resp) => {
        this.firstData = resp;
      })
      .catch((err) => {
        throw err;
      });

    const responseConfig = await this.http.get("messageCredentials");

    this.fbData = responseConfig;

    const audiences = await this.http.get(`facebookCollection`);

    this.audiences = audiences.map((x) => ({
      name: x.audienceName,
      value: x.audienceId,
    }));

    this.audiences.push({ name: "Criar Nova", value: "create-new" });

    if (this.actualNodeProp?.collectionName) {
      this.name = this.actualNodeProp.collectionName;
    }

    if (this.actualNodeProp?.collectionId) {
      this.collectionId = this.actualNodeProp.collectionId;
    }
  },
  data() {
    return {
      name: null,
      firstData: [],
      genOptions: [],
      collectionId: null,
      audiences: [{ name: "Criar Nova", value: "create-new" }],
      http: new ApiClient(),
      fieldMap: {},
      databaseFields: [],
      showCredentialForm: false,
      fbData: {
        fb_ad_account_id: "",
        fb_auth_token: "",
      },
      facebookFields: [
        "Telefone",
        "Nascimento",
        "Nome",
        "Sobrenome",
        "Cidade",
        "Cep",
        "Pais",
      ],
      googleFields: ["Telefone", "Nome", "Sobrenome", "Email", "Cep", "Pais"],
      maleIdentifier: null,
      femaleIdentifier: null,
    };
  },
  props: {
    actualNodeProp: { type: Object, defaul: null },
    databaseConfigId: { type: String, default: "" },
    isGoogleModal: { type: Boolean, default: false },
  },
  watch: {
    fieldMap(value) {
      if (value["Genero"] && !this.genOptions.length) {
        this.genOptions = [
          ...new Set(this.firstData.map((x) => x[value["Genero"]])),
        ];
      }
    },
  },
  methods: {
    async submit() {
      if (!this.isGoogleModal) {
        await this.testFacebookIntegration();
      } else {
        this.insertName();
      }
    },
    async insertName() {
      if (
        !this.collectionId ||
        (this.collectionId === "create-new" && !this.name)
      ) {
        this.$toast.warning("Selecione uma audiencia");
        return;
      }

      this.fieldMap["Genero"] =
        this.fieldMap["Genero"] +
        `_${this.maleIdentifier}_${this.femaleIdentifier}`;

      let fieldJson = JSON.stringify(this.fieldMap);

      console.log('dasdsads')

      this.$emit("close", {
        name: this.name,
        collectionId: this.collectionId,
        fieldMap: fieldJson,
      });
    },
    async testFacebookIntegration() {
      const facebookReturn = await this.http.post(
        `facebookCollection/testIntegration`,
        this.fbData
      );

      console.log(facebookReturn);

      if (!facebookReturn.success) {
        this.showCredentialForm = true;
        this.$toast.error(facebookReturn.message);
      } else {
        this.insertName();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  border-radius: 15px;
  color: $z-black-1;
  padding: $z-s-1;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &__left {
      span {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 15px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__body {
    margin: $z-s-1 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: $z-s-1 $z-s-3;

    & > div {
      flex: 1 1 400px;
      .title {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
        padding: 0.5rem;

        span {
          font-weight: 300;
          font-size: 20px;
          line-height: 23px;
        }
      }
    }
  }
  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
