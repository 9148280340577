<template>
  <div class="modal">
    <div class="modal__right">
      <div class="modal__right__header">
        <div class="modal__right__header__title">
          <span>{{ pageTitles[currentPage] }} </span>
          <!-- <strong>{{ fatherDiamondClicked }}</strong> -->
        </div>
        <div class="modal__right__header--actions">
          <v-icon class="mr-1"> $info_question_mark </v-icon>
          <v-icon @click="$emit('close')">mdi-close</v-icon>
        </div>
      </div>
      <div class="modal__right__body">
        <div class="audiences">
          <ActionItemButton
            v-for="(item, i) in havePermissions(audiences, isAudienceProp)"
            :key="i"
            :data-prop="item"
            class="audiences__item"
            @clicked="emitClick"
          />
        </div>
        <div class="messages">
          <ActionItemButton
            v-for="(item, i) in messages"
            :key="i"
            :data-prop="item"
            class="messages__item"
            @clicked="emitClick"
          />
        </div>
        <div class="tests">
          <ActionItemButton
            v-for="(item, i) in havePermissions(tests, fatherDiamondClicked)"
            :key="i"
            :data-prop="item"
            class="tests__item"
            @clicked="emitClick"
          />
        </div>
      <div class="medias">
          <ActionItemButton
            v-for="(item, i) in medias"
            :key="i"
            :data-prop="item"
            class="medias__item"
            @clicked="emitClick"
          />
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
import { audiences, messages, tests, medias } from "../data";
import ActionItemButton from "./actionItemButton.vue";
export default {
  components: {
    ActionItemButton,
  },
  props: {
    isAudienceProp: { type: Boolean, default: false },
    // usar essa info futuramente, para não permitir que o unir step apareça, caso o step já tenha um merge
    fatherDiamondClicked: { type: Boolean, default: false },
  },
  data() {
    return {
      currentPage: 0,
      audiences: audiences,
      messages: messages,
      tests: tests,
      medias: medias,
      internalRoutes: [
        {
          name: "Adicione rápido",
          value: 0,
        },
        {
          name: "Audiência",
          value: 1,
        },
        {
          name: "Canais",
          value: 2,
        },
        {
          name: "Decisões",
          value: 3,
        },
        {
          name: "Ações de api",
          value: 4,
        },
      ],
      pageTitles: {
        0: "Adicione uma ação rapidamente",
        1: "Audiência",
        2: "Canais",
        3: "Decisões",
        4: "Ações da api",
      },
    };
  },
  methods: {
    emitClick(data) {
      this.$emit("clicked", data);
    },

    havePermissions(datas, validators) {
      let ARR = [];

      if (validators) {
        ARR = datas;
      } else {
        ARR = datas.filter((d) => d.isToShow === true);
      }

      return ARR;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.modal {
  background: #ffffff;
  padding: $z-s-1;
  border-radius: 15px;
  color: $z-black-1;
  display: flex;
  gap: $z-s-1;
  //   height: 100%;
  //     overflow-y: auto;
  //     @include trackScrollBar;
  &__left {
    flex: 1 1 200px;
    &__list {
      &__item {
        .active {
          color: #0273c3;
        }
        strong {
          cursor: pointer;
        }
        &:not(:last-child) {
          margin-bottom: $z-s-1;
        }
      }
    }
  }
  &__right {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $z-s-1;

      &__title {
        span {
          font-weight: 600;
          line-height: 15px;
          font-size: 0.85rem;
        }
      }
    }
    &__body {
      display: flex;
      flex-direction: column;
      gap: $z-s-1;
      .audiences,
      .messages,
      .tests,
      .medias {
        display: flex;
        gap: $z-s-1;
        flex-wrap: wrap;
        // align-items: stretch;
        justify-content: space-evenly;

        &__item {
          flex: 1 1 100px;
          //   border: 2px solid red;
          //   height: 10vh;
          // min-height: 100% !important;
        }
      }
    }
  }
}
</style>
