<template>
  <div class="modal">
    <div class="header">
      <div class="header__title">
        <span>Aguardar até uma data</span>
      </div>
      <div class="header__description">
        <span> Selecione uma período específico para aguardar o período terminar: </span>
      </div>
    </div>

    <div class="body">
      <div class="body__top">
        <z-select label="Dias" :items="[]" :hideDetails="true" />
      </div>
      <div class="body__bottom">
        <div>
          <z-input label="0 dias" :hideDetails="true" />
        </div>
        <div>
          <span> a </span>
        </div>
        <div>
          <z-input label="0 dias" :hideDetails="true" />
        </div>
      </div>
    </div>

    <div class="modal--actions">
      <z-btn large text="Cancelar" />
      <z-btn large primary text="Incluir" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  border-radius: 15px;
  color: $z-black-1;
  padding: $z-s-1;

  .header {
    &__title {
      margin-bottom: $z-s-1;
      span {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 15px;
        text-transform: uppercase;
      }
    }
    &__description {
      margin-bottom: $z-s-1;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    gap: $z-s-1;
    &__bottom {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }

  &--actions {
    margin-top: $z-s-1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $z-s-1;
  }
}
</style>
